import { React, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DataTable from 'react-data-table-component';
import { ColorRing } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteOrderRequest, fetchOrderRequest } from '../../redux_store/actions/orderActions';
import { BASE_URL } from '../../apis/url_constants';

const Order = () => {
  const [showAlert, setShowAlert] = useState(null);

  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();

  const { order, loading, error } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    dispatch(fetchOrderRequest());
  }, [dispatch]);



  const handleDelete = (row) => {
    const confirmAlert = (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => handleConfirmDelete(row)}
        onCancel={hideAlert}
      >
        This action cannot be undone.
      </SweetAlert>
    );

    setShowAlert(confirmAlert);
  };


  const handleConfirmDelete = (row) => {
    dispatch(deleteOrderRequest(row.id));
    hideAlert();
  };

  const hideAlert = () => {
    setShowAlert(null);
  };

  console.log(order);
  const handleEdit = (row) => {
    // Implement your edit logic here
    console.log('Edit row:', row);
  };

  const handleView = (row) => {
    // Implement your view logic here
    console.log('View row:', row);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };


  const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

  const formatDate = (datetimeString) => {
    const date = new Date(datetimeString);
    const day = date.getDate(); // Get the day (1-31)
    const month = date.getMonth() + 1; // Get the month (0-11) and add 1 to match the desired range (1-12)
    const year = date.getFullYear(); // Get the full year

    // Format the components as needed (e.g., zero padding)
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

console.log(order);
  // Define your custom columns (in priority order)
  const customColumns = [
    {
      key: 'orderShipment',
      column: {
        name: 'Images',
        selector: (row) => {
          return row.orderProducts && row.orderProducts.length > 0
            ? row.orderProducts.map((item) => item.product.image ? (
              <img src={`${BASE_URL}${item.product.image}`} style={{ width: '50px', height: '50px', objectFit: 'contain' }} />
            ) : null)
            : '';
        },
        sortable: true,
      },
    },
    {
      key: 'products',
      column: {
        name: 'Product',
        selector: (row) => {
          return row.orderProducts && row.orderProducts.length > 0
            ? row.orderProducts.map((item) => item.name).join(', ')
            : '';
        },
        sortable: true,
      },
    },
    {
      key: 'orderProducts',
      column: {
        name: 'Varients/Size',
        selector: (row) => {
          if (Array.isArray(row.orderProducts) && row.orderProducts.length > 0) {
            const orderProduct = row.orderProducts[0];
            if (Array.isArray(orderProduct.orderProductOptions) && orderProduct.orderProductOptions.length > 0) {
              return orderProduct.orderProductOptions.map((item) => item.value).join(', ');
            }
          }
          return '';
        },
        sortable: true,
      },
    },
    {
      key: "amount",
      column: {
        name: 'Amount',
        selector: (row) => row.amount,
        sortable: true,
      }
    },
    {
      key: "user",
      column: {
        name: 'User',
        selector: (row) => (row.user ? row.user.fullName : ''),
        sortable: true,
      },
    },
    {
      key: "date",
      column: {
        name: 'Date',
        selector: (row) => formatDate(row.date),
        sortable: true,
      }
    },
    {
      key: "paymentType",
      column: {
        name: 'Payment Type',
        selector: (row) => row.paymentType,
        sortable: true,
      }
    },
    {
      key: "paymentStatus",
      column: {
        name: 'Payment Status',
        selector: (row) => row.paymentStatus,
        sortable: true,
      }
    },
    {
      key: "paymentId",
      column: {
        name: 'payment Id',
        selector: (row) => row.paymentId,
        sortable: true,
      }
    },
    {
      key: "transactionId",
      column: {
        name: 'transaction Id',
        selector: (row) => row.transactionId,
        sortable: true,
      }
    },
    
  ];

  const columnsToRemove = ['Id', 'Invoice', 'Type', 'BankName', 'GatewayName', 'Address', 'AddressId', 'UserId', 'CreatedAt', 'LastModified'];
  const filteredColumns = customColumns.filter((column) => !columnsToRemove.includes(column.name));
  const modifiedColumns = [
    ...customColumns.map(col => col.column),
    ...filteredColumns,
  ];

  const actionsColumn = {
    name: 'Actions',
    cell: (row) => (
      <>
        <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-2'></i>
        <Link to={`/OrderDetails/${row.id}`}><i className='ti ti-eye text-info fs-6 px-2'></i></Link>
      </>
    ),
    sortable: false,
  };

  // Add actions column at the end
  const finalColumns = [actionsColumn, ...modifiedColumns];

  return (
    <>
      <div className='col-12'>
        <div className="card">
          <div className='card-header d-flex justify-content-between align-items-center'>
            <h3>Orders</h3>
          </div>
          <div className='card-body'>
            <DataTable
              pagination
              columns={finalColumns}
              data={order}
              selectableRows
              selectableRowsHighlight
              onSelectedRowsChange={handleRowSelected}
              selectedRows={selectedRows}
              progressPending={loading}
              progressComponent={<ColorRing />}
            />
            {showAlert}
          </div>
        </div>
      </div>

    </>
  )
}

export default Order